import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import data from "../services/config";
import UploadProduct from "./UploadProduct";
import { filterNonBrokenImages } from "../services/helper";

const { apiurlFunction } = data;

const ProductScraper = () => {
  const [productURL, setProductURL] = useState("");
  const [productSource, setProductSource] = useState("daraz"); // Default source
  const [productInfo, setProductInfo] = useState(null);
  const [Scrapping, setScrapping] = useState(false);
  const [ChunkSize, setChunkSize] = useState(0);

  async function handleSubmit(e) {
    setScrapping(true);
    e.preventDefault();
    setProductInfo(null);

    const scrapData = {
      url: productURL,
      source: productSource,
      chunk: ChunkSize,
    };
    try {
      const response = await axios.post(
        `${apiurlFunction}/scrape/${productSource}`,
        scrapData
      );

      setScrapping(false);

      if (productSource === "daraz-niches") {
        return toast.info(response.data.message);
      }

      let product_info = { ...response.data, short_desc: [] };

      filterNonBrokenImages(product_info.images)
        .then((validUrls) => {
          product_info.images = validUrls;
          setProductInfo(product_info);
          console.log("Non-broken image URLs:", validUrls);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } catch (error) {
      setScrapping(false);
      toast.error(error.message);
      // console.error("Error scraping and creating product:", error);
    }
  }

  const showChunk = () => {
    return productSource === "daraz-niches" ? true : false;
  };

  return (
    <div className="product-scraper">
      <h2>Product Scraper</h2>
      {!productInfo && (
        <form onSubmit={handleSubmit}>
          <label>
            Product URL:
            <input
              type="text"
              value={productURL}
              onChange={(e) => setProductURL(e.target.value)}
            />
          </label>
          <label>
            Scrap Source:
            <select
              value={productSource}
              onChange={(e) => setProductSource(e.target.value)}
            >
              {/* <option value="daraz-niches">Daraz Niches</option> */}
              <option value="daraz">Daraz Product</option>
              <option value="pehnawastore">Pehnawastore</option>
              {/* Add more source options here */}
            </select>
          </label>
          {showChunk() && (
            <label>
              Chunk Size:
              <input
                type="number"
                value={ChunkSize}
                onChange={(e) => setChunkSize(e.target.value)}
              />
            </label>
          )}

          <button type="submit">Scrape and Create Product</button>
          {Scrapping && <p>Please wait ...</p>}
        </form>
      )}
      {productInfo && <UploadProduct productDataDefault={productInfo} />}
    </div>
  );
};

export default ProductScraper;
