import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Toast } from 'react-bootstrap';
// import { GetVendorOrders } from './../services/http';
// import config from './../services/config';
import './MyOrder.css';

function MyOrders({ VendorOrders }) {
	const [orders, setOrders] = useState([]);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const [error, setError] = useState(null);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setOrders(VendorOrders);
		setFilteredOrders(VendorOrders);
	}, [VendorOrders]);

	const handleOrderClick = (order) => {
		setSelectedOrder(order);
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		const filtered = orders.filter((order) => order.id.toString().includes(searchTerm));
		setFilteredOrders(filtered);
	};

	return (
		<Container>
			<div className="mt-3 mb-3">
				<input
					type="text"
					className="form-control"
					placeholder="Search by Order ID"
					onChange={handleSearch}
				/>
			</div>
			{error && <div>Error: {error}</div>}
			<Row>
				{filteredOrders.map((order) => (
					<Col
						key={order.id}
						md={12}
						className="mb-3"
					>
						<div
							className="order-card"
							onClick={() => handleOrderClick(order)}
						>
							<h4>Order ID: {order.id}</h4>
							<p className="mb-0 mt-3">
								<b>Phone Number :</b> {order.billing.phone}
							</p>
							<p>
								<b>Total Amount :</b> ${order.total}
							</p>
						</div>
					</Col>
				))}
			</Row>
			<OrderModal
				order={selectedOrder}
				show={showModal}
				handleClose={closeModal}
			/>
		</Container>
	);
}

const OrderModal = ({ order, show, handleClose }) => {
	const [showToast, setShowToast] = useState(false);

	const copyToClipboard = () => {
		if (!order) return;

		const { id, total, billing } = order;
		const { full_name, address_1, phone } = billing || {};

		const orderDetails = [
			`Order ID: ${id}`,
			full_name && `Full Name: ${full_name}`,
			phone && `Phone Number: ${phone}`,
			`Total Amount: $${total}`,
			address_1 && `Address: ${address_1}`,
		]
			.filter(Boolean)
			.join('\n');

		navigator.clipboard.writeText(orderDetails);
		setShowToast(true); // Show toast notification
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title className="text-center">Order Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<b>Order ID:</b> {order ? order.id : ''}
				</p>
				{order && order.shipping && order.shipping.full_name && (
					<p>
						<b>Full Name:</b> {order.shipping.full_name}
					</p>
				)}
				<p>
					<b>Phone Number:</b> {order ? order.billing.phone : ''}
				</p>
				<p>
					<b>Total Amount:</b> {order ? `$${order.total}` : ''}
				</p>
				{order && order.shipping && order.shipping.address_1 && (
					<p>
						<b>Address:</b> {order.shipping.address_1}
					</p>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={copyToClipboard}
				>
					Copy to Clipboard
				</Button>
				{/* Toast Notification */}
				<Toast
					show={showToast}
					onClose={() => setShowToast(false)}
					delay={1000}
					autohide
					style={{
						position: 'absolute',
						top: '10px',
						right: 0,
						minWidth: 200,
						backgroundColor: '#28a745',
						color: '#fff',
					}}
				>
					<Toast.Body>Order details copied to clipboard!</Toast.Body>
				</Toast>
			</Modal.Footer>
		</Modal>
	);
};

export default MyOrders;
