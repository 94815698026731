import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import OrderIcon from "../assets/images/order.png";
import ProductIcon from "../assets/images/products.png";

const Home = ({ TotalProducts, TotalOrders }) => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  useEffect(() => {
    console.log(TotalOrders);
    setTotalProducts(TotalProducts);
    setTotalOrders(TotalOrders);
  }, []);

  const stats = [
    { title: "My Products", value: totalProducts, image: ProductIcon },
    { title: "My Orders", value: totalOrders, image: OrderIcon },
  ];

  // console.log(stats);

  return (
    <Container className="p-4">
      <Row>
        {stats.map((stat, index) => (
          <Col key={index} md={6} className="mb-3">
            <Card className="p-3" style={{ backgroundColor: "#f0f0f0" }}>
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5>{stat.title}</h5>
                    <h3>{stat.value}</h3>
                  </div>
                  <img src={stat.image} alt={stat.title} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Home;
