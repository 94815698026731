import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Toast } from 'react-bootstrap';
import logo from './../logo.png';
import './Login.css';
import { RegisterWhatsApp, VerifyWhatsApp, SetupStore } from './../services/http';
import { pkCities } from '../services/config';

function Register() {
	const [step, setStep] = useState(1);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [fullName, setFullName] = useState('');
	const [storeName, setStoreName] = useState('');
	const [cityName, setCityName] = useState('');
	const [error, setError] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [Working, setWorking] = useState(false); // State for loader
	const navigate = useNavigate();

	const handleSubmitPhoneNumber = async (e) => {
		e.preventDefault();

		if (!phoneNumber) {
			setError('Please enter your WhatsApp number.');
			return;
		}

		try {
			setWorking(true); // Show loader
			const response = await RegisterWhatsApp({ mobile: phoneNumber });
			setWorking(false); // Hide loader

			console.log('Response from RegisterWhatsApp:', response);

			if (response.success) {
				// Proceed to the next step after successful registration
				setStep(2);
			} else {
				setError('Error: ' + response.error);
			}
		} catch (error) {
			console.error('Error registering user:', error.message);
			setError('Error registering user. Please try again.');
		}
	};

	const handleSubmitPassword = async (e) => {
		e.preventDefault();

		try {
			setWorking(true); // Show loader
			const response = await VerifyWhatsApp({ mobile: phoneNumber, code: password });
			setWorking(false); // Hide loader

			if (response.success) {
				// Proceed with the registration process
				setStep(3);
			} else {
				setError('Error: ' + response.error);
				setShowToast(true); // Show the toast
			}
		} catch (error) {
			console.error('Error verifying pin:', error.message);
			setError('Error verifying pin. Please try again.');
		}
	};

	const handleSubmitRegistration = async (e) => {
		e.preventDefault();

		try {
			setWorking(true); // Show loader
			const response = await SetupStore({
				fullname: fullName,
				city_name: cityName,
				store_name: storeName,
				mobile: phoneNumber,
			});
			setWorking(false); // Hide loader

			if (response.success) {
				// Navigate to the home page
				navigate('/');
			} else {
				setError('Error: ' + response.error);
			}
		} catch (error) {
			console.error('Error setting up store:', error.message);
			setError('Error setting up store. Please try again.');
		}
	};

	return (
		<div className="loginForm">
			<Toast
				show={showToast}
				onClose={() => setShowToast(false)}
				delay={3000}
				autohide
				style={{
					position: 'absolute',
					right: '10px',
					minWidth: '200px',
					borderRadius: '8px',
					backgroundColor: '#dc3545',
					color: '#fff',
					zIndex: '9999',
				}}
			>
				<Toast.Body className='text-center'>Please Enter Valid Code</Toast.Body>
			</Toast>
			<div className="pv-logo">
				<img
					src={logo}
					alt="PakVariety Logo"
				/>
			</div>
      
			<div className="text-center">
				{step === 1 && (
					<form onSubmit={handleSubmitPhoneNumber}>
						<input
							type="text"
							placeholder="Enter your WhatsApp number"
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
						<button
							type="submit"
							disabled={!phoneNumber}
						>
							Next
						</button>
						{Working && <p>Please wait...</p>}
						<p className="mt-2">
							Already have an account? <Link to="/login">Login</Link>
						</p>
					</form>
				)}
				{step === 2 && (
					<form onSubmit={handleSubmitPassword}>
						<input
							type="text"
							placeholder="WhatsApp: Pin Code"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button type="submit">Next</button>
						{Working && <p>Please wait...</p>}
					</form>
				)}
				{step === 3 && (
					<form onSubmit={handleSubmitRegistration}>
						<input
							type="text"
							placeholder="Enter your full name"
							value={fullName}
							onChange={(e) => setFullName(e.target.value)}
						/>
						<select
							value={cityName}
							onChange={(e) => setCityName(e.target.value)}
						>
							<option value="">Select your city</option>
							{pkCities.cities.map(
								(
									city,
									index // Accessing cities property here
								) => (
									<option
										key={index}
										value={city}
									>
										{city}
									</option>
								)
							)}
						</select>
						<input
							className="mt-4"
							type="text"
							placeholder="Enter your store name"
							value={storeName}
							onChange={(e) => setStoreName(e.target.value)}
						/>
						<button type="submit">Register</button>
						{Working && <p>Please wait...</p>}
					</form>
				)}
			</div>
		</div>
	);
}

export default Register;
