const data = {
  // apiurl: "http://ec2-3-238-186-49.compute-1.amazonaws.com:8080/api",
  // apiurlFunction: "http://ec2-23-21-143-88.compute-1.amazonaws.com:8080/api",
  apiurlFunction:
    "https://lpvby5y4xzvky4ysubzobfuthi0jxpyh.lambda-url.us-east-1.on.aws/api",
  siteApiURL: "https://pakvariety.com/wp-json/pv/v1",
  loginSite: "https://pakvariety.com",
  consumerKey: "ck_e2cb0ed57b893b6ba65640ea4cde304b41253bee",
  consumerSecret: "cs_2ccf26c1e55dc7311e24557c17cb803f9379e8ea",
  // siteApiURL: "https://plugins.nmdevteam.com/wp-json/pv/v1",
  // loginSite: "https://plugins.nmdevteam.com",
  // consumerKey: "ck_4ae4f03126b5d7140b61dc3a868f9625f5fd7336",
  // consumerSecret: "cs_d24bc9d3431891dac4a83b83861a5107d39cdecf",
  tokensLimit: 50000,
  productFeatures: [
    "COD Payment",
    "Fast Delivery",
    "Washable",
    "Winter Collection",
    "Summer Collection",
    "New Arrial",
    "Outfit",
    "Party Dress",
    "Fancy Dress",
  ],
};

export default data;

// Cities Data
const pkCities = {
  cities: [
    "Ahmadpur East",
    "Ahmed Nager Chatha",
    "Ali Khan Abad",
    "Alipur",
    "Arifwala",
    "Attock",
    "Bhera",
    "Bhalwal",
    "Bahawalnagar",
    "Bahawalpur",
    "Bhakkar",
    "Burewala",
    "Chenab Nagar",
    "Chillianwala",
    "Choa Saidanshah",
    "Chakwal",
    "Chak Jhumra",
    "Chichawatni",
    "Chiniot",
    "Chishtian",
    "Chunian",
    "Dajkot",
    "Daska",
    "Davispur",
    "Darya Khan",
    "Dera Ghazi Khan",
    "Dhaular",
    "Dina",
    "Dinga",
    "Dhudial Chakwal",
    "Dipalpur",
    "Faisalabad",
    "Fateh Jang",
    "Ghakhar Mandi",
    "Gojra",
    "Gujranwala",
    "Gujrat",
    "Gujar Khan",
    "Harappa",
    "Hafizabad",
    "Haroonabad",
    "Hasilpur",
    "Haveli Lakha",
    "Jalalpur Jattan",
    "Jampur",
    "Jaranwala",
    "Jhang",
    "Jhelum",
    "Kallar Syedan",
    "Kalabagh",
    "Karor Lal Esan",
    "Kasur",
    "Kamalia",
    "Kāmoke",
    "Khanewal",
    "Khanpur",
    "Khanqah Sharif",
    "Kharian",
    "Khushab",
    "Kot Adu",
    "Jauharabad",
    "Lahore",
    "Islamabad",
    "Lalamusa",
    "Layyah",
    "Lawa Chakwal",
    "Liaquat Pur",
    "Lodhran",
    "Malakwal",
    "Mamoori",
    "Mailsi",
    "Mandi Bahauddin",
    "Mian Channu",
    "Mianwali",
    "Miani",
    "Multan",
    "Murree",
    "Muridke",
    "Mianwali Bangla",
    "Muzaffargarh",
    "Narowal",
    "Nankana Sahib",
    "Okara",
    "Renala Khurd",
    "Pakpattan",
    "Pattoki",
    "Pindi Bhattian",
    "Pind Dadan Khan",
    "Pir Mahal",
    "Qaimpur",
    "Qila Didar Singh",
    "Raiwind",
    "Rajanpur",
    "Rahim Yar Khan",
    "Rawalpindi",
    "Sadiqabad",
    "Sagri",
    "Sahiwal",
    "Sambrial",
    "Samundri",
    "Sangla Hill",
    "Sarai Alamgir",
    "Sargodha",
    "Shakargarh",
    "Sheikhupura",
    "Shujaabad",
    "Sialkot",
    "Sohawa",
    "Soianwala",
    "Siranwali",
    "Tandlianwala",
    "Talagang",
    "Taxila",
    "Toba Tek Singh",
    "Vehari",
    "Wah Cantonment",
    "Wazirabad",
    "Yazman",
    "Zafarwal",
  ],
};

export { pkCities };
