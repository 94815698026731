import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { applyAI } from "../services/http";
import UploadImages from "../components/UploadImage";
import Select from "react-select"; // Import react-select
import { toast } from "react-toastify";
import { getCurrentUser } from "../services/auth";
import { HashLoader } from "react-spinners";

const currentVendor = getCurrentUser();
const ProductForm = ({
  product,
  categories,
  onSaveProduct,
  onProductCancel,
}) => {
  const [formStatus, setFormStatus] = useState("init");
  const [ImagesURL, setImagesURL] = useState([]);
  const [Working, setWorking] = useState(false);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    short_description: "",
    regular_price: 0,
    sale_price: 0,
    categories: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      console.log(product);
      // If a product is provided, populate the form with its details
      setFormData({
        id: product.id,
        name: product.name || "",
        description: product.description || "",
        short_description: product.short_description || "",
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        tags: product.tags,
      });
      const selected_categories =
        product.categories.map((category) => ({
          value: category.id,
          label: category.name,
        })) || [];
      setSelectedCategories(selected_categories);
      setFormStatus("ai_ready");
    }
    // Options for react-select
    const category_options = categories.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    setcategoryOptions(category_options);
  }, [product, categories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formStatus === "image_ready") {
      setWorking(true);
      let { product_info } = formData;
      const total_images = ImagesURL.length;
      const resp = await applyAI(product_info, total_images);
      const { refinedProduct } = resp;
      product_info = { ...formData, ...refinedProduct };
      setFormData(product_info);
      setWorking(false);
      setFormStatus("ai_ready");
    } else if (formStatus === "ai_ready") {
      if (selectedCategories.length === 0)
        return toast.error("Categories cannot be null");

      // formatting images
      const images = ImagesURL.map((src, index) => {
        return {
          src: src,
          alt: formData.images_alt[index],
        };
      });

      // formatting categories
      const categories = selectedCategories.map((c) => ({ id: c.value }));

      const product_info = {
        ...formData,
        images,
        categories,
      };

      if (!product_info.regular_price) return toast.error("Price is required");

      // updating product description
      const store_name = currentVendor.store_info.store_name;
      const store_url = currentVendor.store_url;

      // console.log(currentVendor);

      // adding vendor id
      product_info.vendor_id = currentVendor.vendor_id;

      product_info.description += product_info.short_description;
      product_info.description += `<p><a alt="Store Name ${store_name}" href="${store_url}">Store: ${store_name}</a>`;
      product_info.description += `<a target="_blank" alt="Store Name ${store_name}" href="https://www.leopardscourier.com">Shipping Provider: Leapords Courier</a></p>`;
      if (
        product_info.meta_description &&
        product_info.meta_description.length > 155
      ) {
        product_info.meta_description =
          product_info.meta_description.substring(0, 152) + "...";
      }

      delete product_info.images_alt;
      delete product_info.product_info;

      // console.log(product_info);
      onSaveProduct(product_info);
    }
  };

  const handleImageUploaded = (image_url) => {
    setWorking(false);
    // console.log(image_url);
    const images = [...ImagesURL, ...image_url];
    setImagesURL(images);
    // setShowProductForm(true);
    setFormStatus("image_ready");
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <Container>
      <HashLoader
        loading={Working}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {formStatus === "init" && (
        <UploadImages onImageUploaded={handleImageUploaded} />
      )}
      <Form onSubmit={handleFormSubmit}>
        {formStatus === "image_ready" && (
          <Form.Group controlId="productInfo" className="mt-4">
            <Form.Label>
              Enter your product info to generate title and description from
              ChatGPT
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={9}
              placeholder="Product Info..."
              name="product_info"
              value={formData.product_info}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}

        {formStatus === "ai_ready" && (
          <>
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="productName">
              <Form.Label>Regular Price</Form.Label>
              <Form.Control
                type="number"
                name="regular_price"
                value={formData.regular_price}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="productName">
              <Form.Label>Sale Price</Form.Label>
              <Form.Control
                type="number"
                name="sale_price"
                value={formData.sale_price}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="productDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="productShortDescription">
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="short_description"
                value={formData.short_description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="productCategories">
              <Form.Label>Categories</Form.Label>
              <Select
                options={categoryOptions}
                isMulti
                value={selectedCategories}
                onChange={setSelectedCategories}
              />
            </Form.Group>
          </>
        )}

        {formStatus !== "init" && (
          <div className="d-flex align-items-center mt-4">
            <Button variant="primary" type="submit">
              {formStatus === "image_ready"
                ? "Generate Content"
                : "Save Product"}
            </Button>
            <Button variant="link" color="red" onClick={onProductCancel}>
              Cancel
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default ProductForm;
