import React from 'react';
import { Container, Form, Card } from 'react-bootstrap';
import { getCurrentUser } from '../services/auth';

function MyProfile() {
    const currentUser = getCurrentUser(); // Get current user data
    const storeInfo = currentUser && currentUser.store_info ? currentUser.store_info : {};

    return (
        <Container>
            <h1 className="mt-3 mb-4">Profile Information</h1>
            <Card className="shadow mb-4 pb-3">
                <Card.Body>
                    <Form>
                        <Form.Group controlId="formVendorId">
                            <Form.Label>Vendor ID</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={currentUser && currentUser.vendor_id ? currentUser.vendor_id : ''}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStoreName">
                            <Form.Label>Store Name</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={storeInfo && storeInfo.store_name ? storeInfo.store_name : ''}
                            />
                        </Form.Group>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={currentUser && currentUser.user_display_name ? currentUser.user_display_name : ''}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={storeInfo && storeInfo.phone ? storeInfo.phone : ''}
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={currentUser && currentUser.email ? currentUser.email : ''}
                            />
                        </Form.Group> */}
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default MyProfile;
