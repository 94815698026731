import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";

function SendFeedback() {
    const [feedback, setFeedback] = useState("");
    const [sending, setSending] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!feedback) return;

        setSending(true);

        try {
            // Simulating sending feedback to backend
            setTimeout(() => {
                console.log("Feedback sent:", feedback);
                setFeedback("");
                setSending(false);
            }, 1000);
        } catch (error) {
            console.error("Error sending feedback:", error);
            setSending(false);
        }
    };

    const handleChange = (e) => {
        setFeedback(e.target.value);
    };

    return (
        <Container className="send-feedback-container">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFeedback">
                    <Form.Label>Your Feedback</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Enter your feedback"
                        value={feedback}
                        onChange={handleChange}
                        disabled={sending}
                    />
                </Form.Group>
                <Button
                className="mt-2"
                    variant="primary"
                    type="submit"
                    disabled={!feedback || sending}
                >
                    {sending ? "Sending..." : "Send Feedback"}
                </Button>
            </Form>
        </Container>
    );
}

export default SendFeedback;
