import React, { useEffect, useState, useSyncExternalStore } from "react";
import UploadImage from "./UploadImage";
import axios from "axios";
import Select from "react-select"; // Import react-select
import data from "../services/config";
import { toast } from "react-toastify";
import ProductPreview from "./ProductPreview";
import http, { SaveProduct } from "../services/http";
import QualityCheck from "./QualityCheck";
import { getCurrentUser } from "../services/auth";

const { apiurl, productFeatures, siteApiURL, tokensLimit } = data;

export default function UploadProduct({ productDataDefault }) {
  const [showProductForm, setShowProductForm] = useState(false);
  const [ImagesURL, setImagesURL] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [ProductReady, setProductReady] = useState(false);
  const [NewProductIno, setNewProductIno] = useState({});
  const [Spinned, setSpinned] = useState(false);
  const [Working, setWorking] = useState(false);
  const [IsRefined, setIsRefined] = useState(false);
  const [IsCreated, setIsCreated] = useState(false);
  const [VendorID, setVendorID] = useState("");
  const [StoreName, setStoreName] = useState("");
  const [StoreURL, setStoreURL] = useState("");
  const [TokenUsage, setTokenUsage] = useState(0);

  const [productData, setProductData] = useState({
    title: "",
    price: "",
    categories: [],
    description: "",
    images: [],
    short_desc: [],
    specifications: [],
  });

  // Fetch categories from the backend
  useEffect(() => {
    const current_user = getCurrentUser();
    if (current_user) {
      setVendorID(current_user.vendor_id);
      setStoreName(current_user.store_info.name);
      setStoreURL(current_user.store_url);
    }

    const getFreshTokenUsage = async () => {
      try {
        const response = await http.get(
          `${siteApiURL}/get-token-usage?vendor_id=${current_user.vendor_id}`
        );
        const token_used = response.data.data.total_tokens;
        setTokenUsage(token_used);
        // console.log(token_used);
      } catch (error) {
        toast.error("Error while fetching token", error.message);
        // console.error("Error fetching tokens:", error);
      }
    };

    getFreshTokenUsage();

    const fetchCategories = async () => {
      try {
        const response = await http.get(`${apiurl}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    if (productDataDefault) {
      const images = [...productDataDefault.images];
      // console.log(images);
      setImagesURL(images);
      setShowProductForm(true);
      setProductData(productDataDefault);
    }
  }, [productDataDefault]);

  // Options for react-select
  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const tokeLimitAavailable = () => {
    return TokenUsage <= tokensLimit;
  };

  const handleImageUploaded = (image_url) => {
    setWorking(false);
    // console.log(image_url);
    const images = [...ImagesURL, ...image_url];
    setImagesURL(images);
    setShowProductForm(true);
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFeatureChange = (e) => {
    const selectedFeature = e.target.value;

    // Create a new array with selected features
    let newShortDesc = [...productData.short_desc];

    // Check if the selected feature is already in the array
    if (newShortDesc.includes(selectedFeature)) {
      // Remove the feature if it's already selected
      newShortDesc = newShortDesc.filter(
        (feature) => feature !== selectedFeature
      );
    } else {
      // Add the feature if it's not selected
      newShortDesc.push(selectedFeature);
    }

    // Update the productData with the new short_desc array
    setProductData({
      ...productData,
      short_desc: newShortDesc,
    });
  };

  function isValidJSONObject(obj) {
    if (typeof obj !== "object" || obj === null) {
      return false; // Not an object or null
    }

    try {
      JSON.stringify(obj);
      return true;
    } catch (error) {
      return false;
    }
  }

  const generateForOpenAI = () => {
    return {
      ...productData,
      Spinned,
      categories: selectedCategories,
    };
  };

  const handlePrepare = async (e) => {
    e.preventDefault();
    if (selectedCategories.length === 0)
      return toast.error("Categories cannot be null");

    setWorking(true);
    let prodData = generateForOpenAI();
    // first time providing images
    prodData = { ...prodData, images: ImagesURL };
    let response = await preparingProductAfterAI(prodData);
    // console.log(response);
    let { refinedProduct, openai_usage } = response;
    if (!isValidJSONObject(refinedProduct))
      return toast.error("Data not valid, try again");

    prodData.images = prodData.images.map((src, index) => {
      return {
        src: src,
        alt: refinedProduct.images_alt[index],
      };
    });

    const wc_product = { ...prodData, ...refinedProduct, openai_usage };
    setProductData(wc_product);
    setProductReady(true);
    setWorking(false);

    // Refining is off for now
    // setIsRefined(true);
  };

  async function preparingProductAfterAI(prodData) {
    try {
      const { data: refined_info } = await axios.post(
        `${apiurl}/refine-product`,
        {
          prodData,
        }
      );

      setWorking(false);
      return refined_info;
    } catch (ex) {
      console.log(ex);
    }
  }

  const handleQualityFailed = async (e, prompt) => {
    e.preventDefault();
    if (selectedCategories.length === 0)
      return toast.error("Categories cannot be null");

    setWorking(true);
    setIsRefined(false);
    prompt = `${prompt}\nGenerate output in json for the required keys\n`;
    let prodData = generateForOpenAI();
    prodData = { ...prodData, user_input: prompt };
    let response = await preparingProductAfterAI(prodData);
    const { refinedProduct, openai_usage } = response;
    if (!isValidJSONObject(refinedProduct))
      return toast.error("Data not valid, try again");

    prodData.images = prodData.images.map((src, index) => {
      return {
        src: src,
        alt: refinedProduct.images_alt[index],
      };
    });
    const wc_product = { ...prodData, ...refinedProduct, openai_usage };
    // console.log(wc_product);
    setProductData(wc_product);
    setIsRefined(true);
    setWorking(false);
  };

  const handleQualityPassed = () => {
    setProductReady(true);
  };

  const handleCreateProduct = async () => {
    // return console.log(productData);
    setWorking(true);

    try {
      const pData = { ...productData, vendor_id: VendorID };

      // updating product description
      pData.description += pData.short_description;
      pData.description += `<p><a alt="Store Name ${StoreName}" href="${StoreURL}">Store: ${StoreName}</a>`;
      pData.description += `<a target="_blank" alt="Store Name ${StoreName}" href="https://www.leopardscourier.com">Shipping Provider: Leapords Courier</a></p>`;
      if (pData.meta_description.length > 155) {
        pData.meta_description =
          pData.meta_description.substring(0, 152) + "...";
      }

      const { resp: newProductInfo } = await SaveProduct(pData);

      // return console.log(newProductInfo);

      // const { data: newProductInfo } = await axios.post(
      //   `${apiurl}/create-product`,
      //   {
      //     productData: pData,
      //   }
      // );

      setWorking(false);
      setNewProductIno(newProductInfo);
      toast.info(`Product created successfully`);
      setProductReady(false);
      setShowProductForm(false);
      setProductData({
        title: "",
        price: "",
        categories: [],
        description: "",
        images: [],
        short_desc: [],
      });
      setIsCreated(true);
    } catch (e) {
      console.log(e);
      // const { data: error } = e.response;
      toast.error(e);
      setWorking(false);
    }
  };

  const handlePostOnSocialMedia = async () => {
    // Now postin on Facebook
    const fbMessage = `${NewProductIno.productTitle}
    Price: Rs. ${NewProductIno.productPrice}
    Cash On Delivery
    Shipping Free
    ${NewProductIno.productLink}`;

    const pData = { fbMessage, imgURL: NewProductIno.productThumbnail };
    // return console.log(pData);
    setWorking(true);
    try {
      setWorking(false);
      const { data } = await axios.post(`${apiurl}/social/fb`, pData);
      toast.success("Successfully posted on social networks");
    } catch (e) {
      toast.error(e.message);
      setWorking(false);
    }
  };

  return (
    <div className="upload-product-container">
      {!tokeLimitAavailable() && (
        <div>
          Sorry, you have used all your tokens. Your limit is {tokensLimit} and
          you have used {TokenUsage}
        </div>
      )}

      {!showProductForm && !Working && !IsCreated && tokeLimitAavailable() && (
        <UploadImage
          onImageUploadStart={() => setWorking(true)}
          onImageUploaded={handleImageUploaded}
        />
      )}

      {Working && <p>Please wait ...</p>}

      {showProductForm && !ProductReady && !Working && (
        <div className="product-form">
          {ImagesURL.map((image, index) => (
            <img
              src={image}
              width="125"
              key={index}
              alt="Feature image"
              style={{ objectFit: "cover" }}
            />
          ))}
          <form onSubmit={handlePrepare}>
            <label>
              Title:
              <input
                type="text"
                name="title"
                value={productData.title}
                onChange={handleProductInputChange}
              />
            </label>
            <label>
              Price:
              <input
                type="text"
                name="price"
                value={productData.price}
                onChange={handleProductInputChange}
              />
            </label>
            <label>
              Categories:
              <div className="category-select">
                <Select
                  options={categoryOptions}
                  isMulti
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                />
              </div>
            </label>
            <label>
              Description:
              <textarea
                name="description"
                value={productData.description}
                onChange={handleProductInputChange}
                rows={15}
              />
            </label>
            <label>
              Short Description:
              <div className="inline-checkboxes">
                {productFeatures.map((feature) => (
                  <label key={feature} className="inline-checkbox-label">
                    <input
                      type="checkbox"
                      name="short_desc"
                      value={feature}
                      checked={productData.short_desc.includes(feature)}
                      onChange={handleFeatureChange}
                    />
                    {feature}
                  </label>
                ))}
              </div>
            </label>
            <label htmlFor="spinned">
              <input
                type="checkbox"
                onChange={(e) => setSpinned(e.target.checked)}
                value="Spin"
                id="spinned"
              />
              Spin Info
            </label>

            {IsRefined && (
              <QualityCheck
                RefinedInfo={productData}
                onQualityFailed={handleQualityFailed}
                onQualityPassed={handleQualityPassed}
              />
            )}

            <button type="submit">Prepare Product</button>
          </form>
        </div>
      )}
      {ProductReady && !Working && (
        <ProductPreview
          productData={productData}
          onCreateProduct={handleCreateProduct}
          onRegenerate={() => setProductReady(false)}
        />
      )}

      {NewProductIno && (
        <div>
          {/* <p>
            <button onClick={handlePostOnSocialMedia}>
              Post On Social Media
            </button>
          </p> */}
          <a href={NewProductIno.productLink} target="_blank">
            See New Proudct
          </a>
        </div>
      )}
    </div>
  );
}
