import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMyProducts } from "./../services/http";

const MyProducts = ({ vendorId, products, onDeleteProduct, onEditProduct }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Products, setProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const intersectionRef = useRef();

  useEffect(() => {
    if (products.length > 0) setProducts(products);

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
      }
    };
  }, []);

  const fetchProducts = async (vendorId, page) => {
    try {
      setIsLoading(true);
      const newProducts = await getMyProducts(vendorId, page);
      setCurrentPage(page + 1);
      return newProducts;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleIntersection = async (entries) => {
    if (entries[0].isIntersecting && !isLoading) {
      const nextPageProducts = await fetchProducts(vendorId, currentPage);
      if (nextPageProducts.length > 0) {
        const updatedProducts = [...filteredProducts, ...nextPageProducts];
        setProducts(updatedProducts);
      }
    }
  };

  const filteredProducts = Products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <div className="sticky-top bg-white">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </Col>
        <Col>
          <Link to="/add-product" className="btn btn-primary">
            Add Product
          </Link>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Badge variant="secondary">{filteredProducts.length} Products</Badge>
        </Col>
      </Row>
      <Row className="mt-3">
        {filteredProducts.map((product, index) => (
          <Col key={index} md={4} className="mb-3">
            <Card>
              <Card.Header>{product.name}</Card.Header>
              <Card.Body>
                {product.images.length > 0 && (
                  <img
                    src={product.images[0].src}
                    alt={product.name}
                    className="img-fluid"
                  />
                )}
              </Card.Body>
              <Card.Footer className="text-left">
                Price:{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: product.price_html }}
                ></span>
              </Card.Footer>

              <Card.Footer className="d-flex align-items-center justify-content-between">
                <span
                  className="fa fa-2x fa-edit mr-3"
                  onClick={() => onEditProduct(product)}
                ></span>
                <span
                  className="fa fa-2x fa-trash"
                  onClick={() => onDeleteProduct(product.id)}
                ></span>
                <a target="_blank" href={`${product.permalink}`}>
                  <span className="fa fa-2x fa-eye ml-3"></span>
                </a>
              </Card.Footer>
            </Card>
            {index === Products.length - 1 && <div ref={intersectionRef}></div>}
          </Col>
        ))}
      </Row>
      <div className="fixed-bottom mb-5 mr-3">
        <button
          className="btn btn-light btn-lg"
          onClick={handleScrollToTop}
          title="Move to Top"
        >
          <i className="fa fa-arrow-up"></i>
        </button>
      </div>
      {isLoading && (
        <div className="text-center mt-3">
          <p>Loading...</p>
        </div>
      )}
    </Container>
  );
};

export default MyProducts;
