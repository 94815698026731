import axios from "axios";
import data from "./config";
import { getCurrentUser } from "./auth";

const User = getCurrentUser();

const {
  loginSite: wcEndPoint,
  consumerKey,
  consumerSecret,
  apiurlFunction,
  siteApiURL,
} = data;

// New axio interceptor syntax from
// source: https://stackoverflow.com/questions/68714143/how-can-i-use-axios-interceptors-to-add-some-headers-to-responses
axios.interceptors.request.use(
  (config) => {
    // console.log(config.url);

    if (
      config.url !==
      "https://lpvby5y4xzvky4ysubzobfuthi0jxpyh.lambda-url.us-east-1.on.aws/api/uploadImages"
    )
      config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getCategories = async () => {
  try {
    // WooCommerce API endpoint for categories
    const categoriesEndpoint = `${wcEndPoint}/wp-json/wc/v3/products/categories`;

    // Authentication headers with consumer key and secret
    const authHeaders = {
      headers: {
        Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
    };

    // Make the GET request to fetch categories
    const response = await axios.get(categoriesEndpoint, authHeaders);

    // Return the categories from the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const getMyProducts = async () => {
  try {
    // WooCommerce API endpoint for products
    const product_ids = User.product_ids.join(",");
    const productsEndpoint = `${wcEndPoint}/wp-json/wc/v3/products?include=${product_ids}`;

    // Authentication headers with consumer key and secret
    const authHeaders = {
      headers: {
        Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
    };

    // Query parameters to filter by post_author (vendor_id), set page number, and load 20 products per page
    const params = {
      params: {
        page: 1,
        per_page: 100, // Set the number of products per page
      },
    };

    // Make the GET request to fetch products with the specified vendor_id, page number, and per_page
    const response = await axios.get(productsEndpoint, {
      ...authHeaders,
      ...params,
    });

    // Return the products from the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const SaveProduct = async (productData) => {
  try {
    const productsEndpoint = `${wcEndPoint}/wp-json/wc/v3/products`;

    let keywords = [];

    if (typeof productData.keywords === "string") {
      keywords = productData.keywords
        .split(",")
        .map((keyword) => keyword.trim());
    } else if (Array.isArray(productData.keywords)) {
      keywords = productData.keywords.map((keyword) => keyword.trim());
    }

    const tags = [
      ...(productData.tags || []),
      ...keywords.map((keyword) => ({ name: keyword })),
    ];

    let meta_data = [{ key: "vendor_id", value: productData.vendor_id }];

    if (productData.meta_description) {
      meta_data = [
        ...meta_data,
        { key: "meta_description", value: productData.meta_description },
        { key: "focus_keyword", value: productData.focus_keyword },
      ];
    }

    const productPayload = {
      name: productData.name,
      regular_price: productData.regular_price.toString(),
      sale_price: productData.sale_price.toString(),
      type: "simple",
      categories: productData.categories,
      description: productData.description,
      short_description: productData.short_description,
      images: productData.images,
      tags,
      meta_data,
    };

    // Authentication headers with consumer key and secret
    const authHeaders = {
      Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    };

    // Make the POST request to save the product
    const response = await axios.post(productsEndpoint, productPayload, {
      headers: authHeaders,
    });

    // Return the products from the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error while saving product:", error);
    throw error;
  }
};

// export function GetVendorOrders(vendor_id) {
//   const url = `${siteApiURL}/get-vendor-orders?vendor_id=${vendor_id}`;
//   return axios.get(url);
// }

export function GetVendorOrders() {
  // WooCommerce API endpoint for products
  const url = `${wcEndPoint}/wp-json/wc/v3/orders`;

  const authHeaders = {
    headers: {
      Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
    },
  };

  const params = {
    params: {
      page: 1,
      per_page: 100, // Set the number of products per page
    },
  };

  return axios.get(url, { ...authHeaders, ...params });
}

export function GetVendorProducts(vendor_id) {
  // https://pakvariety.com/wp-json/pv/v1/
  const url = `${siteApiURL}/get-vendor-products?vendor_id=${vendor_id}`;
  return axios.get(url);
}

export function DeleteProduct(id) {
  const url = `${apiurlFunction}/delete-product`;
  return axios.post(url, { id });
}

export const applyAI = async (product_info) => {
  try {
    // WooCommerce API endpoint for products
    const productsEndpoint = `${apiurlFunction}/apply-ai`;

    // Query parameters to filter by post_author (vendor_id), set page number, and load 20 products per page
    const params = {
      product_info,
    };

    // Make the GET request to fetch products with the specified vendor_id, page number, and per_page
    const response = await axios.post(productsEndpoint, {
      ...params,
    });

    // Return the products from the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error applying AI:", error);
    throw error;
  }
};

export const RegisterWhatsApp = async (data) => {
  try {
    // WooCommerce API endpoint for registering WhatsApp
    const url = `${siteApiURL}/register-wa`;

    // Make the POST request to register WhatsApp
    const response = await axios.post(url, data);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error registering user:", error);
    throw error;
  }
};

export const VerifyWhatsApp = async (data) => {
  try {
    const url = `${siteApiURL}/verify-wa`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Error verifying pin:", error);
    throw error;
  }
};

export const SetupStore = async (data) => {
  try {
    const url = `${siteApiURL}/setup-store`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Error setting up store:", error);
    throw error;
  }
};

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
