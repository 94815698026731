import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import ProductScraper from "./components/ScrapProduct";
import Layout from "./components/Layout";
import UploadProduct from "./components/UploadProduct";
import Login from "./pages/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLocalStorage from "./services/useLocalStorage";
import Home from "./pages/Home";
import {
  SaveProduct,
  DeleteProduct,
  getCategories,
  getMyProducts,
  GetVendorOrders,
} from "./services/http";
import MyProducts from "./pages/MyProducts";
import ProductForm from "./pages/ProductForm";
import { ClipLoader, HashLoader, RotateLoader } from "react-spinners";
import MyOrders from "./pages/MyOrders";
import MyProfile from "./pages/MyProfile";
import FeedBack from "./pages/FeedBack";
import Register from "./pages/Register";

const App = () => {
  const [User, setUser] = useLocalStorage("pv_user", null);
  const [Categories, setCategories] = useLocalStorage("pv_categories", []);
  const [Products, setProducts] = useLocalStorage("pv_products", []);
  const [VendorOrders, setVendorOrders] = useLocalStorage("pv_orders", []);
  const [TokenUsage, setTokenUsage] = useLocalStorage("pv_token_usage", null);
  const [Working, setWorking] = useState(false);
  const [Product, setProduct] = useState(null);

  const vendor_id = !User ? null : User.vendor_id;

  const navigate = useNavigate();

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        // load all user data like products, orders setc
        const categories = await getCategories();
        setCategories(categories);

        const products = await getMyProducts();
        setProducts(products);

        // const { data: orders } = await GetVendorOrders();
        // setVendorOrders(orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    // Call the async function
    fetchData();
  }, [vendor_id, setVendorOrders, setCategories, setProducts]); // Empty dependency array means this effect runs once when the component mounts

  // Function to check authentication
  const isAuthenticated = () => {
    // return true;
    // Replace with your logic to check if the user is authenticated
    // console.log("User");
    return User !== null;
  };

  // Higher-order component to protect routes
  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/login" />;
  };

  const handleLogin = async (user_info) => {
    setUser(user_info);
    // return console.log(user_info);
    window.location = "/";
  };

  const handleLogout = () => {
    setUser(null);
    window.location = "/login";
  };

  const handleProductSave = async (product_info) => {
    // return console.log(product_info);

    try {
      setWorking(true);
      const product = await SaveProduct(product_info);
      setWorking(false);

      let products = [...Products];
      if (product_info.id) {
        const found = products.find((p) => p.id === product_info.id);
        const index = products.indexOf(found);
        products[index] = product;
      } else {
        products = [product, ...products];
      }
      setProducts(products);
      navigate("/");
    } catch (e) {
      console.error(e);
      toast.error(`Error while saving product ${e.message}`);
    }
  };

  const handleProductCancel = () => {
    setProduct(null);
    navigate("/");
  };

  const handleEditProduct = (product) => {
    setProduct(product);
    navigate("/add-product");
  };

  const handleDeleteProduct = async (productId) => {
    const c = window.confirm("Are you sure?");
    if (!c) return;
    setWorking(true);
    try {
      await DeleteProduct(productId);
      const after_delete = Products.filter((p) => p.id !== productId);
      setProducts(after_delete);
      setWorking(false);
      toast.success("Product deleted successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  const override = {
    display: "block",
    margin: "25px auto",
    borderColor: "red",
  };

  return (
    <>
      <HashLoader
        loading={Working}
        cssOverride={override}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Routes>
        <Route path="/login" element={<Login onLoginSuccess={handleLogin} />} />{" "}
        <Route path="/register" element={<Register />} />{" "}
        {/* Login with different layout */}
        <Route path="/" element={<Layout onLogout={handleLogout} />}>
          <Route
            path="/"
            element={
              <ProtectedRoute
                element={
                  <MyProducts
                    vendorId={vendor_id}
                    products={Products}
                    onDeleteProduct={handleDeleteProduct}
                    onEditProduct={handleEditProduct}
                  />
                }
              />
            }
          />
          {/* Add this line */}
          <Route path="/register" element={<Register />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute
                element={
                  <Home
                    TotalOrders={VendorOrders.length}
                    TotalProducts={Products.length}
                  />
                }
              />
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute
                element={<MyOrders VendorOrders={VendorOrders} />}
              />
            }
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<MyProfile />} />}
          />
          <Route
            path="/feedback"
            element={<ProtectedRoute element={<FeedBack />} />}
          />
          {
            <Route
              path="/products"
              element={
                <ProtectedRoute
                  element={
                    <MyProducts
                      vendorId={vendor_id}
                      products={Products}
                      onDeleteProduct={handleDeleteProduct}
                      onEditProduct={handleEditProduct}
                    />
                  }
                />
              }
            />
          }
          <Route
            path="/scrap"
            element={<ProtectedRoute element={<ProductScraper />} />}
          />
          <Route
            path="/add-product"
            element={
              <ProtectedRoute
                element={
                  <ProductForm
                    product={Product}
                    categories={Categories}
                    onSaveProduct={handleProductSave}
                    onProductCancel={handleProductCancel}
                  />
                }
              />
            }
          />
          <Route
            path="/upload"
            element={<ProtectedRoute element={<UploadProduct />} />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
