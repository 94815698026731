import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { getCurrentUser } from "./../services/auth";
import "./../assets/Layout.css";
import logo from "./../logo.png";
import ToggleMenu from "./Menu";

const Layout = ({ onLogout }) => {
  const [Username, setUsername] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const current_user = getCurrentUser();
    if (current_user) {
      setUsername(current_user.store_info["name"]);
    }
  }, []);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    onLogout(); // Call the passed-in onLogout function
    // Redirect or navigate to login or another page if necessary
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  return (
    <div className="layout-container">
      <nav className="nav-bar">
        <div>
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>
          <Link to="/home">V 1.3</Link>
        </div>
        <div className="nav-list-wrapper">
          {/* Display username */}
          <div className="username-wrapper text-end">
            <p className="nav-item nav-item-name">Hi, {Username}</p>
            <a href="/logout" className="nav-link" onClick={handleLogoutClick}>
              Logout
            </a>
          </div>

          {/* ToggleMenu Component */}
          {/* <ToggleMenu
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            handleLogoutClick={handleLogoutClick}
          /> */}
        </div>
      </nav>
      <Outlet />
    </div>
  );
};

export default Layout;
